import { useState, useRef, useCallback } from 'react'

import dayjs from 'dayjs'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import {
  UpdateUserEUTCMutation,
  UpdateUserEUTCMutationVariables,
} from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'
import { Metadata, useMutation } from '@redwoodjs/web'

import Button from 'src/components/Library/Button/Button'
import endUserTermsAndConditionsText from 'src/lib/endUserTermsAndConditionsText'
import generalCollectionNotificationStatement from 'src/lib/generalCollectionNotificationStatement'
import TheHubIcon from 'src/lib/icons/TheHubIcon/TheHubIcon'
import { useAuth } from 'src/Providers'

const EndUserTermsAndConditionsPromptPage = () => {
  const { currentUser } = useAuth()

  if (currentUser?.userData?.userAgreementAccepted) {
    navigate(routes.home())
  }

  const [acceptLoading, setAcceptLoading] = useState(false)
  const [hasScrolledToEndOfTerms, setHasScrolledToEndOfTerms] = useState(false)
  const [secondAcceptEnabled, setSecondAcceptEnabled] = useState(false)

  const [termsAccepted, setTermsAccepted] = useState(false)

  const firstScrollRef = useRef<HTMLDivElement>(null)
  const secondScrollRef = useRef<HTMLDivElement>(null)

  const onTermsScroll = () => {
    if (firstScrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = firstScrollRef.current
      if (scrollTop + clientHeight + 100 >= scrollHeight) {
        setHasScrolledToEndOfTerms(true)
      }
    }
  }

  const onGeneralCollectionNotificationScroll = () => {
    if (secondScrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = secondScrollRef.current
      if (scrollTop + clientHeight + 100 >= scrollHeight) {
        setSecondAcceptEnabled(true)
      }
    }
  }

  const UPDATE_USER = gql`
    mutation UpdateUserEUTCMutation($input: UpdateUserInput!, $id: Int!) {
      updateUser(input: $input, id: $id) {
        id
        name
        userAgreementAccepted
      }
    }
  `

  const [updateUserMutation] = useMutation<
    UpdateUserEUTCMutation,
    UpdateUserEUTCMutationVariables
  >(UPDATE_USER, {
    onCompleted: () => {
      setAcceptLoading(false)
      location.reload()
    },
  })

  const updateUser = useCallback(() => {
    setAcceptLoading(true)
    return updateUserMutation({
      variables: {
        id: currentUser.userData.id,
        input: {
          userAgreementAccepted: true,
          userAgreementAcceptedAt: dayjs().toISOString(),
        },
      },
    })
  }, [currentUser, updateUserMutation])

  return (
    <>
      <Metadata
        title="End User Terms And Conditions Prompt"
        description="End User Terms And Conditions Prompt"
      />

      <div className="relative flex h-screen flex-col place-items-center bg-slate-50 p-4 md:p-8">
        <div className="h-12 w-60">
          <a href="/">
            <TheHubIcon variant="default" />
          </a>
        </div>
        <div className="h-3/4 w-full max-w-[800px] rounded-lg bg-transparent pt-12 text-center md:px-12">
          {/** Stage 1: End User Terms and Conditions **/}
          {!termsAccepted ? (
            <>
              <h2 className="text-3xl font-extrabold text-slate-700">
                End User Terms and Conditions Agreement
              </h2>
              <p className="pt-3 font-medium text-slate-600">
                Please read and accept the following End User Terms and
                Conditions to continue.
              </p>
              <p className="pt-6 text-sm font-medium text-slate-500">
                You are required to accept these terms and conditions to use the
                application.
              </p>
              <p className="text-sm font-medium text-slate-500">
                Continued use of the application will be understood as
                acceptance of these terms and conditions.
              </p>
              <br />
              <div
                className="border-1 h-1/2 overflow-y-scroll rounded-lg border border-slate-200 bg-white p-4"
                onScroll={onTermsScroll}
                ref={firstScrollRef}
              >
                <ReactMarkdown
                  className="prose prose-sm max-w-none text-left text-slate-600 prose-headings:text-slate-700 prose-strong:text-slate-600"
                  remarkPlugins={[remarkGfm]}
                >
                  {endUserTermsAndConditionsText}
                </ReactMarkdown>
              </div>
              <br />
              {!hasScrolledToEndOfTerms && (
                <p className="pt-4 text-lg font-medium text-slate-600">
                  Please scroll to the bottom of the agreement to accept it.
                </p>
              )}
              {hasScrolledToEndOfTerms && (
                <Button
                  variant="contained"
                  loading={acceptLoading}
                  disabled={acceptLoading}
                  onClick={() => setTermsAccepted(true)}
                >
                  Accept Terms and Conditions
                </Button>
              )}
            </>
          ) : (
            <>
              {/** Stage 2: General Collection Notification Statement **/}
              <h2 className="text-3xl font-extrabold text-slate-700">
                General Collection Notification Statement
              </h2>
              <p className="pt-3 font-medium text-slate-600">
                Please read and accept the following notification statement to
                continue.
              </p>
              <br />
              <div
                className="border-1 h-1/2 overflow-y-scroll rounded-lg border border-slate-200 bg-white p-4"
                onScroll={onGeneralCollectionNotificationScroll}
                ref={secondScrollRef}
              >
                <ReactMarkdown
                  className="prose prose-sm max-w-none text-left text-slate-600 prose-headings:text-slate-700 prose-strong:text-slate-600"
                  remarkPlugins={[remarkGfm]}
                >
                  {generalCollectionNotificationStatement}
                </ReactMarkdown>
              </div>
              <br />
              {!secondAcceptEnabled && (
                <p className="pt-4 text-lg font-medium text-slate-600">
                  Please scroll to the bottom of the notification statement to
                  accept it.
                </p>
              )}
              {secondAcceptEnabled && (
                <Button
                  variant="contained"
                  loading={acceptLoading}
                  disabled={acceptLoading}
                  onClick={updateUser}
                >
                  Accept All and Continue
                </Button>
              )}
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default EndUserTermsAndConditionsPromptPage
